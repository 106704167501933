<template>
  <div class="resize">
    <div class="l-col resize-head">
      <label class="typo__label small-title">{{
        $t('port.description', { text: type === 'servers' ? 'серверу' : 'порту' })
      }}</label>
    </div>

    <div class="l-col">
      <PickList
        v-model="products"
        data-key="id"
        :pt="{
          moveAllToTargetButton: {},
          moveAllToSourceButton: {},
        }"
      >
        <template #sourceheader> Все группы </template>
        <template #targetheader> Выбранные </template>
        <template #item="slotProps">
          <div class="flex flex-wrap p-2 align-items-center gap-3">
            <div class="flex-1 flex flex-column gap-2">
              <span class="font-bold">{{ slotProps.item.name }}</span>
            </div>
          </div>
        </template>
      </PickList>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import PickList from 'primevue/picklist';
export default {
  name: 'EditFirewalls',
  components: { /*BaseInput,*/ PickList },
  mixins: [setFocus],
  props: {
    tariff: {
      type: Object,
      default: () => {},
    },
    // type: {
    //   type:
    // },
  },
  data() {
    return {
      newNamePort: '',
      initialNames: null,
      products: [],
    };
  },
  computed: {
    type() {
      return this.tariff && this.tariff._source ? this.tariff._source : null;
    },
    // firewalls() {
    //   return this.$store.state.moduleStack.firewalls.map(x => {
    //     return {
    //       name: x.name,
    //       id: x.id,
    //     };
    //   });
    // },
    // selectedFirewalls() {
    //   return this.tariff.security_groups.map(x => {
    //     return {
    //       name: this.$store.state.moduleStack.firewalls.find(id => id.id === x).name,
    //       id: x,
    //     };
    //   });
    // },
    // freeFirewalls() {
    //   return this.tariff.security_groups.map(x => {
    //     return {
    //       name: this.$store.state.moduleStack.firewalls.find(id => id.id !== x).name,
    //       id: x,
    //     };
    //   });
    // },
    // products() {
    //   return [this.freeFirewalls, this.selectedFirewalls];
    // },
  },
  watch: {
    products: function (event) {
      if (event && this.type === 'ports') {
        this.$emit(
          'change',
          this.products[1].map(x => x.id)
        );
      }
      if (event && this.type === 'servers') {
        this.$emit(
          'change',
          this.products[1].map(x => x.name)
        );
      }
    },
    type: {
      handler: function (event) {
        // console.log(event);
        if (event && event === 'ports') this.fetchPortsProducts();
        if (event && event === 'servers') this.fetchServersProducts();
      },
      immediate: true,
    },
  },
  mounted() {
    this.newNamePort = this.name;
    // if (this.type === 'ports') this.fetchPortsProducts();
    // if (this.type === 'servers') this.fetchServersProducts();
  },
  methods: {
    fetchPortsProducts() {
      const id = this.$store.state.moduleStack.firewalls.map(x => x.id);
      const selectedId = this.tariff.security_groups;
      const lastId = id.filter(d => !selectedId.includes(d));

      const selectedFirewalls = selectedId.map(x => {
        return {
          name: this.$store.state.moduleStack.firewalls.find(id => id.id === x).name,
          id: this.$store.state.moduleStack.firewalls.find(id => id.id === x).id,
        };
      });
      const freeFirewalls = lastId.map(x => {
        return {
          name: this.$store.state.moduleStack.firewalls.find(id => id.id === x).name,
          id: this.$store.state.moduleStack.firewalls.find(id => id.id === x).id,
        };
      });
      this.products = [freeFirewalls, selectedFirewalls];
    },
    fetchServersProducts() {
      const id = this.$store.state.moduleStack.firewalls.map(x => {
        return {
          id: x.id,
          name: x.name,
        };
      });
      const selectedId =
        this.tariff.security_groups && this.tariff.security_groups.length
          ? Array.from(new Set(this.tariff.security_groups.map(x => x.name)))
          : [];
      const lastId = id.filter(d => !selectedId.includes(d.name));

      const selectedFirewalls = selectedId.map(x => {
        return {
          name: this.$store.state.moduleStack.firewalls.find(name => name.name === x).name,
          id: this.$store.state.moduleStack.firewalls.find(name => name.name === x).id,
        };
      });
      this.initialNames = selectedFirewalls;
      this.$emit('servers', this.initialNames);
      const freeFirewalls = lastId.map(x => {
        return {
          name: this.$store.state.moduleStack.firewalls.find(id => id.id === x.id).name,
          id: this.$store.state.moduleStack.firewalls.find(id => id.id === x.id).id,
        };
      });
      this.products = [freeFirewalls, [...new Set(selectedFirewalls)]];
      // this.products = [freeFirewalls, Array.from(new Set(selectedFirewalls))];
    },
    onChange() {},
  },
};
</script>

<i18n>
{
  "ru": {
    "port": {
      "name": "Новое название порта",
      "description" : "Добавить или удалить группу безопасности к этому %{text} из списка доступных групп безопасности."
    },
    "sure": {
      "confirm": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.p-picklist-list-wrapper {
  max-width: 20rem;
  //width: 20rem;
}
.resize {
  text-overflow: unset;
  overflow: visible;
  &-head {
    margin-bottom: 2rem;
  }
}
  .gap-2{
    max-width: 20rem;

    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:hover {
      text-overflow: unset;
      overflow: visible;
      z-index: 1000;
    }
  }
</style>
